// React Imports
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { motion as m } from 'framer-motion';

// Form Handling Imports
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Icon Imports
import { FaPhone as PhoneIcon } from "react-icons/fa6";
import { MdEmail as MailIcon } from "react-icons/md";
import { MdLocationOn as LocationIcon } from "react-icons/md";

// Image Imports
import headIcon from '../../img/contact-icon.png'

// Data Imports
import { contacts } from '../../js/contacts';

// Component Imports
import Available from '../widgets/Available';
import HeadContainer from '../widgets/HeadContainer';
import SloganContainer from '../widgets/SloganContainer';


export default function Contact() {
    // Automatically detect if running locally
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const messageURL = isLocal ? 'http://localhost:8000/message' : 'https://dmf-plumbing-and-heating-ba30ffedf4f1.herokuapp.com/message';


    const schema = yup.object().shape({
        providedName: yup.string().required("You forgot to give your name."),
        providedEmail: yup.string().email("Not valid email address").required("You forgot to give your email."),
        providedSubject: yup.string().required("You forgot to give a subject."),
        providedMessage: yup.string().required("You forgot to type a message."),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data, event) => {
        event.preventDefault();
        try {
            // Modify the form data to match backend expected keys
            const newDate = new Date();
            const localDate = newDate.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
            const localTime = newDate.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
            const formData = {
                senderName: data.providedName,
                senderEmail: data.providedEmail,
                senderPhone: "", // Add a field in your form if you collect phone numbers
                text: data.providedMessage,
                date: localDate,
                time: localTime
            };
            const result = await fetch(messageURL, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            //console.log("Request Sent:", formData);
            //console.log("Response Status:", result.status);

            if (!result.ok) {
                const errorText = await result.text(); // Get error message from the response
                //console.log("Error:", errorText);
                alert("Oops! Something went wrong. We were unable to send your message. Please try again later or contact us directly.");
            } else {
                resetForm();
                alert("Thank you! Your message has been successfully sent. I will get back to you shortly.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    function resetForm() {
        reset({
            providedName: '',
            providedEmail: '',
            providedSubject: '',
            providedMessage: ''
        });
    }

    return (
        <m.div
            className='page contact'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>

            <HeadContainer h2="Contacts" headIcon={headIcon} />

            <SloganContainer h1="Get in touch" />

            <div className="wrapper">
                <div className='form-container'>

                    <div className='info-box'>
                        <p>For prompt assistance, please contact our office at:</p>
                        <p>
                            <a className='strong' href={`tel:${contacts.phone}`} rel="noopener noreferrer">
                                <PhoneIcon className='icon' />
                                {contacts.phone}
                            </a>
                        </p>

                        <p>If you prefer to reach out via email, you can use the following address:</p>
                        <p>
                            <a className='strong' href={`mailto:${contacts.email}`} rel="noopener noreferrer">
                                <MailIcon className='icon' />
                                <span className='full-email'>{contacts.email}</span>
                                <span className='short-email'>{contacts.email.slice(0, 8)}...</span>
                            </a>
                        </p>

                        <p>You can also visit us at our office located at:</p>
                        <p>
                            <a className='strong' href={contacts.maps} rel="noopener noreferrer" target='_blank'>
                                <LocationIcon className='icon' />
                                {contacts.address.street}, {contacts.address.town}, {contacts.address.county}, {contacts.address.postcode}
                            </a>
                        </p>


                        <p>Alternatively, you can use the contact form below to send us a direct email message, and a dedicated member of our team will respond to your inquiry within 24 hours.</p>

                        <p>We appreciate your comments and questions and look forward to assisting you.</p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {errors.providedName && <span><p className='error'>{errors.providedName?.message}</p></span>}
                        <input placeholder="Name*" {...register('providedName')} />

                        {errors.providedEmail && <span><p className='error'>{errors.providedEmail?.message}</p></span>}
                        <input placeholder="Email*" {...register('providedEmail')} />

                        {errors.providedSubject && <span><p className='error'>{errors.providedSubject?.message}</p></span>}
                        <input placeholder="Subject*" {...register('providedSubject')} />

                        {errors.providedMessage && <span><p className='error'>{errors.providedMessage?.message}</p></span>}
                        <textarea placeholder="Type your message here*" {...register('providedMessage')}></textarea>

                        <div>
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>

            <Available />
        </m.div >
    )
}
