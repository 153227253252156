export const netPrices = {
    vat: 1.2,
    weekdays: {
        dayTime: 85,
        evening: 125,
        night: 195,
    },
    weekends: {
        dayTime: 125,
        night: 195,
    },
}